import React, { useState } from 'react';
import Img from 'gatsby-image';
import AssetsDataContext from '../../../context/assets-data.context';

const ResponsiveImage = ({ images, altText }: { images: Array<{ path: string; media: string }>; altText: string }) => {
    const { assetsData } = React.useContext(AssetsDataContext);
    const [sourceSet, setSourceSet] = useState<any[]>([]);

    React.useEffect(() => {
        const sources = images.map((image) => {
            const fluidImage = assetsData.images.edges.find((fimage: any) => fimage.node.relativePath === image.path);
            if (fluidImage) {
                return { ...fluidImage.node.childImageSharp.fluid, media: image.media };
            }
        });

        setSourceSet(sources);
    }, [assetsData]);

    return <>{sourceSet.length > 0 && <Img fluid={sourceSet ?? []} alt={altText} />}</>;
};

export default ResponsiveImage;
