import React, { ReactElement } from 'react';
import { Color } from '../../../_shared/enums/color';

interface Headline {
    text: any;
    style: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: Color;
}

interface Copytext {
    text: any;
    style?: 'standard' | 'bold' | 'wide';
    horizontalPadding?: string;
    color?: Color;
}

export interface HeadlineCopytextProperties {
    color?: Color;
    headline?: Headline | Headline[];
    copytext?: Copytext | Copytext[];
    alignment?: 'left' | 'right' | 'center' | 'justify' | undefined;
    children?: any;
    outerStyles?: React.CSSProperties;
    innerStyles?: React.CSSProperties;
}

const HeadlineCopytext = ({
    headline,
    copytext,
    alignment,
    color,
    children,
    outerStyles = {},
    innerStyles = { paddingTop: '20px' },
}: HeadlineCopytextProperties) => {
    const [headlines, setHeadlines] = React.useState<Headline[]>([]);
    const [copytexts, setCopytexts] = React.useState<Copytext[]>([]);

    const getHeadline = (headlineElement: Headline, index: number): ReactElement => {
        const HeadlineType: any = `${headlineElement?.style ?? 'h1'}`;
        return (
            <HeadlineType key={index} className={headlineElement.color ? 'color-' + headlineElement.color : undefined}>
                {headlineElement?.text}
            </HeadlineType>
        );
    };

    React.useEffect(() => {
        if (headline) {
            setHeadlines(!Array.isArray(headline) ? [headline] : headline);
        }
        if (copytext) {
            setCopytexts(!Array.isArray(copytext) ? [copytext] : copytext);
        }
    }, []);

    return (
        <div style={Object.assign({ textAlign: alignment }, outerStyles)} className={'color-' + color}>
            {headlines.length > 0 && (
                <>
                    {headlines.map((h: Headline, index: number) => {
                        return getHeadline(h, index);
                    })}
                </>
            )}
            {copytexts.length > 0 && (
                <>
                    {copytexts.map((c: Copytext, index: number) => {
                        return (
                            <p
                                className={[
                                    c.style !== 'standard' ? c.style : undefined,
                                    c.color ? 'color-' + c.color : undefined,
                                ].join(' ')}
                                key={index}
                                style={{ padding: '0 ' + (c.horizontalPadding ? c.horizontalPadding : 0) }}
                            >
                                {c.text}
                            </p>
                        );
                    })}
                </>
            )}
            {children && <div style={innerStyles}>{children}</div>}
        </div>
    );
};

export default HeadlineCopytext;
