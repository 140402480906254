export enum Color {
    Aqua = 'aqua',
    Black = 'black',
    Blue = 'blue',
    Green = 'green',
    Pink = 'pink',
    Purple = 'purple',
    Red = 'red',
    Rose = 'rose',
    White = 'white',
    Yellow = 'yellow',
}
